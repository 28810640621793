import React from "react";
// import { Link } from "gatsby"

import {
  Box,
  Heading,
  Container,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

// import { MdCheckCircle, MdSettings } from "@chakra-ui/icons";
import { FiAward } from "react-icons/fi";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import Map from "../components/mapComponent";

const FaqPage = () => (
  <Layout>
    <SEO title="FAQ" />

    {/* <Stack w="80%" mx="auto" direction={["column", "row"]} spacing="2rem"> */}
    <Box
      mx="auto"
      maxW={900}
      minW={600}
      p="2rem"
      // h="400px"
      bg="gray.100"
      borderRadius="xl"
      centerContent
    >
      <Heading>The FAQ's!</Heading>
      {/* <Text mt={4} fontSize="xl">
        Inform the world about the dangers of the Bearbat.
      </Text> */}

      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              How can I help?
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            Warn and tell your loved ones that this is a dangerous beast.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Is this a real beast?
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            That is what we want to find out.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
    {/* </Stack> */}

    {/* <Link to="/page-2/">Go to page 2</Link> <br /> */}
  </Layout>
);

export default FaqPage;
